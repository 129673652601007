<template>
  <div>
    <b-card v-if="!queryDocumentType">
      <ReportSearch
        report="distribution"
        ref="search"
        :getReport="getReport"
        distribution
      />
    </b-card>
    <b-card>
      <Table
        :loader="tableLoader"
        :items="items.results"
        :fields="fields"
        :sortBy="sortAcs"
        :sortAcs="sortAcs"
        :sortDesc="sortDesc"
        :getDocuments="getReport"
        :saveExcel="saveExcelDoc"
        :total="total"
        type="reportDistributionGroup"/>
      <Paginate
        :current="items.current"
        :next="items.next"
        :prev="items.previous"
        :count="items.count"
        :changePage="changePage" />
    </b-card>
  </div>
</template>

<script>
import Table from '@/components/layouts/Table/Table.vue'
import ReportSearch from '@/components/molecules/ReportSearch/ReportSearch.vue'
import Paginate from '@/components/atoms/Paginate.vue'
import { mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'ReportDistribution',
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  components: {
    Table,
    ReportSearch,
    Paginate
  },
  data () {
    return {
      SUCCESS_CODE,
      items: [],
      fields: [],
      tableLoader: false,
      sortAcs: null,
      sortDesc: null,
      sortName: null,
      params: null,
      total: {},
      queryDocumentType: this.$route.query.type_document
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  },
  mounted () {
    if (this.queryDocumentType) {
      const type = this.queryDocumentType === 'passport' ? 'sailor_passport' : 'qual_doc'
      this.$api.get(`api/v1/reports/back_office/distribution/dpd/crewing/${type}/?crewing_group=${this.$route.query.crewing_group}&${this.search}`).then(response => {
        if (SUCCESS_CODE.includes(response?.code)) {
          this.setDefaultFields()
          let cloneResponse = { ...response.data.results }
          delete cloneResponse.data
          response.data.results.data.map(item => {
            item.link = `dpd/crewing/${item.crewing?.id}/?type_document=${this.queryDocumentType}&search=${this.search}`
            if (!this.fields.find(column => column.key === 'groupID')) {
              this.fields.unshift({ key: 'crewing', label: this.$t('crewingCompany') })
            }
          })

          this.items = response.data
          this.items.results = response.data.results.data
          this.total = cloneResponse
        }
      })
    }
  },
  methods: {
    setDefaultFields () {
      this.fields = [
        { key: 'count', label: this.$i18n.t('itemsAmount') },
        { key: 'distribution_sum', label: this.$i18n.t('distribution') },
        { key: 'profit_sum', label: this.$i18n.t('profit') },
        { key: 'event', label: this.$i18n.t('go'), class: 'mw-0' }]
    },

    getReport (sort = '', params = '', link = null) {
      this.tableLoader = true
      this.items = []
      // Add general table fields (same for each document type)
      this.setDefaultFields()
      this.params = params
      // const url = link || `api/v1/reports/back_office/distribution/${this.$refs.search.dataForm.distributionType.value}/?${params}`
      let customUrl = ''
      if (['sqc', 'crewing_manager', 'dpd'].includes(this.$refs.search.dataForm.distributionType.value)) {
        customUrl = `api/v1/reports/back_office/distribution/${this.$refs.search.dataForm.distributionType.value}/group/?${params}`
      } else {
        customUrl = `api/v1/reports/back_office/distribution/${this.$refs.search.dataForm.distributionType.value}/?${params}`
      }

      const url = link || customUrl
      this.$api.get(url).then(response => {
        let cloneResponse = { ...response.data.results }
        delete cloneResponse.data
        this.tableLoader = false
        if (SUCCESS_CODE.includes(response?.code)) {
          response.data.results.data.map(item => {
            item.searchParams = params
            item.allowSaveExcel = this.$refs.search.dataForm.distributionType.value === 'eti'
            switch (this.$refs.search.dataForm.distributionType.value) {
              case 'sqc':
              case 'crewing_manager':
                item.link = `usual/${this.$refs.search.dataForm.distributionType.value}/crewing_group/${item.group}`
                if (!this.fields.find(column => column.key === 'groupID')) {
                  this.fields.unshift({ key: 'groupID', label: this.$i18n.t('group') })
                }
                break
              case 'dpd':
                item.link = `usual/${this.$refs.search.dataForm.distributionType.value}/document_type/${item.group}`
                if (!this.fields.find(column => column.key === 'groupID')) {
                  this.fields.unshift({ key: 'groupID', label: this.$i18n.t('group') })
                }
                break
              case 'eti':
                item.link = `usual/${this.$refs.search.dataForm.distributionType.value}/crewing/${item.eti.id}`
                if (!this.fields.find(column => column.key === 'eti')) {
                  this.fields.unshift({ key: 'eti', label: this.$i18n.t('eti') })
                }
                break
              case 'medical':
                item.link = `usual/${this.$refs.search.dataForm.distributionType.value}/crewing/${item.medical_institution.id}`
                if (!this.fields.find(column => column.key === 'medical_institution')) {
                  this.fields.unshift({ key: 'medical_institution', label: this.$i18n.t('medicalInstitution') })
                }
                break
              case 'portal':
              case 'sc':
                item.link = `usual/${this.$refs.search.dataForm.distributionType.value}/crewing/${item.branch_office.id}`
                if (!this.fields.find(column => column.key === 'branch_office')) {
                  this.fields.unshift({ key: 'branch_office', label: this.$i18n.t('affiliate') })
                }
                break
              case 'adv_training':
                item.link = `usual/${this.$refs.search.dataForm.distributionType.value}/qualification/${item.educational_institution.id}`
                if (!this.fields.find(column => column.key === 'educational_institution')) {
                  this.fields.unshift({ key: 'educational_institution', label: this.$i18n.t('nameInstitution') })
                }
                break
            }
          })
          this.items = response.data
          this.items.results = response.data.results.data
          this.total = cloneResponse
        }
      })
    },

    changePage (link) {
      this.getReport('', '', link)
    },

    saveExcelDoc (row) {
      const params = this.params
      params.set('institution', row.item.eti.id)
      const url = `api/v1/reports/back_office/distribution/eti/sailor/xlsx/?${params}`
      this.$api.get(url).then(() => {
        this.$notification.success('excelStatement')
      })
    }
  }
}
</script>
